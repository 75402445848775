<template>
  <div class="sbData">
    <UUPILE :type="imgtype" :imglists="upimglist"></UUPILE>
    <div style="text-align: left;padding-top: 20px;">
      <el-select class="xzlex" style="margin-right: 10px;" v-model="valTypeidx" placeholder="请选择类型">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="dqwj" type="info">读取文件</el-button>
<!--      <el-button @click="dqsbsj" type="info">获取设备数据</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "sbData",
  props: {
    datas: {
      default: {}
    },
    imgtype: {
      default: ''
    },
    wjtype: {
      default: ''
    }
  },
  data() {
    return {
      options: [],
      valTypeidx: 1,
      isdrc: true,
      upimglist: []
    }
  },
  created() {
    this._api.publicApi.getOCRType(this.wjtype)
        .then(res => {
          let arr = []
          this.baiduTo = res.BaiduToken
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
            for (let i = 0; i < res.GetListResult.length; i++) {
              arr.push({
                value: i + 1,
                label: res.GetListResult[i].BDOCR_TempName
              })
            }
          }
          this.options = arr
        })
  },
  methods: {
    dqwj() {
      if (!this.isdrc) return
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
            .then(res => {this.$router.push({path: '/employeesLanding'})})
      }
      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }

      this.isdrc = 0

      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: this.wjtype,
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId":'',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId":  this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }

      window.bound.selReport(JSON.stringify(obj));
    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        // let upwj = _this.$refs.uptq.uploadFiles
        // let obj = JSON.parse(JSON.stringify(_this.postData))
        _this.isdrc = 1
        // if (log) log.close()
        _this.showsbsj = false
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let item = {}
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word?datas.ret[i].word.toString():''
              item[datas.ret[i].word_name] = str
            }
            _this.$emit('update:datas', item)
          }
        }


        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportImages[i].ImageBase64, _this.imgtype)
                .then(res => {
                  _this.upimglist.push(res)
                })
          }
        }
        if (res.ReportPages && res.ReportPages.length) {
          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, _this.imgtype)
                .then(res => {
                  _this.upimglist.push(res)
                })
          }
        }
      }
    }
  },
  components: {
    UUPILE: () => import('./CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">

</style>
